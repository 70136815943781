<template>
  <div class="spinner_container flex flex-col space-y-2">
    <main class="card-container">
      <div class="card square-card">
        <div class="down">
          <div class="up">
            <div class="squeeze">
              <div class="rotate-in">
                <div class="rotate-out">
                  <div class="square">
                    <img src="@/assets/images/pizza.png" alt="" width="" height=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card ball-bouncing">
        <div class="ball">
          <img src="@/assets/images/hamburguer.png" alt="" />
        </div>
      </div>

      <div class="card ball-movement">
        <div class="ball">
          <img src="@/assets/images/sushi.png" alt="" />
        </div>
      </div>

      <div class="card counter">
        <div class="loading"></div>
      </div>
    </main>

    <div class="pt-12 text-center">
      <p class="text-gray-500 font-bold">
        Estamos revisando os itens do pedido
      </p>
      <p class="text-gray-500">por favor, aguarde</p>
    </div>
  </div>
</template>

<script>
export default {
  props: [],

  data() {
    return {};
  },
};
</script>


<style lang="postcss" scoped>
.spinner_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: white;
}

.card-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  margin: 0 auto;
  max-width: 100%;
  padding: 2rem;
  width: 45rem;
}

.card {
  align-items: center;
  border-radius: 0.25rem;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 15rem;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 2%;
}

.ball {
  /* background-color: #fff; */
  border-radius: 50%;
  height: 3rem;
  position: absolute;
  width: 3rem;
}

/* Square */
.square-card {
  background-color: #e88905;
  /* background-color: rgba(131,111,255, 0.5);  */
}

.square-card::before {
  animation: shadow 1.6s ease-in-out infinite both;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  bottom: 2rem;
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
  content: "";
  height: 0.1rem;
  left: 25%;
  position: absolute;
  right: 25%;
}

.down {
  animation: down 1.6s ease-in infinite both;
  bottom: 2rem;
  position: absolute;
}

.down .up {
  animation: up 1.6s ease-in-out infinite both;
}

.down .up .squeeze {
  animation: squeeze 1.6s cubic-bezier(0.72, 0.03, 0.28, 0.97) infinite both;
  transform-origin: 50% 100%;
}

.down .up .squeeze .rotate-in {
  animation: rotate-in 1.6s ease-out infinite both;
}

.down .up .squeeze .rotate-in .rotate-out {
  animation: rotate-out 1.6s ease-in infinite both;
}

.square {
  /* background-color: #fff; */
  height: 5rem;
  width: 5rem;
}

@keyframes down {
  0% {
    transform: translateY(-7rem);
  }
  20%,
  100% {
    transform: translateY(0);
  }
}

@keyframes up {
  0%,
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-7rem);
  }
}

@keyframes squeeze {
  0%,
  4% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.2, 0.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate-in {
  0% {
    transform: rotate(-135deg);
  }
  20%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate-out {
  0%,
  80% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(135deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scaleX(0.3);
  }
  45%,
  50% {
    transform: scaleX(0.8);
  }
}
/* End square */

/* Ball bouncing */
.ball-bouncing {
  background-color: #ffb512;
}

.ball-bouncing::before {
  animation: shadowBouncing 1.2s ease-in infinite;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  bottom: 2rem;
  content: "";
  height: 0.15rem;
  position: absolute;
  width: 2rem;
}

.ball-bouncing .ball {
  animation: ballBouncing 1.2s ease-in infinite;
  bottom: 2.1rem;
  transform-origin: bottom;
}

@keyframes ballBouncing {
  0%,
  100% {
    transform: scale(1.5, 0.5);
  }
  20% {
    transform: scaleY(1.2);
  }
  40%,
  80% {
    transform: translateY(-7rem);
  }
  70% {
    transform: translateY(-9rem);
  }
  90% {
    transform: translateY(0);
  }
}

@keyframes shadowBouncing {
  0%,
  100% {
    transform: scale(2, 0.8);
  }
  20% {
    transform: scale(1.2, 0.8);
  }
  40%,
  80% {
    transform: scale(0.5, 0.2);
  }
  70% {
    transform: scale(0.5, 0.15);
  }
  90% {
    transform: scale(1.5, 0.6);
  }
}
/* End ball bouncing */

/* Ball movement */
.ball-movement {
  background-color: #e8b705;
  overflow: hidden;
}

.ball-movement::before {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  bottom: 2rem;
  content: "";
  height: 0.15rem;
  left: 2rem;
  position: absolute;
  right: 2rem;
}

.ball-movement .ball {
  animation: ballMovement 1.6s cubic-bezier(0, 0.98, 1, 0.99) infinite;
  bottom: 2.1rem;
  transform: translate(14rem, -15rem) scale(1.2, 0.5);
  transform-origin: bottom;
}

@keyframes ballMovement {
  0% {
    transform: translate(14rem, -15rem);
  }
  15% {
    transform: translate(7rem, 0rem) scale(1.2, 0.5);
  }
  30% {
    transform: translate(2rem, -10rem) scale(1, 1);
  }
  45% {
    transform: translate(-2rem, 0rem) scale(1.2, 0.8);
  }
  60% {
    transform: translate(-5rem, -5.5rem) scale(1, 1);
  }
  75% {
    transform: translate(-8rem, 0rem) scale(1.1, 0.9);
  }
  100% {
    transform: translate(-14rem, -3rem) scale(1, 1);
  }
}
/* End ball movement */

/* Counter */

.counter {
  /* animation: counter 10s linear infinite alternate;
  counter-reset: count 0; */
  background-color: #ffe205;
}

/* .counter::before {
  color: #fff;
  content: counter(count);
  font-size: 7rem;
  line-height: 1.1;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
} */

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 1;
  }
  20% {
    counter-increment: count 2;
  }
  30% {
    counter-increment: count 3;
  }
  40% {
    counter-increment: count 4;
  }
  50% {
    counter-increment: count 5;
  }
  60% {
    counter-increment: count 6;
  }
  70% {
    counter-increment: count 7;
  }
  80% {
    counter-increment: count 8;
  }
  90% {
    counter-increment: count 9;
    background: none;
  }
  100% {
    counter-increment: count 10;
    background: none;
  }
}

/* End counter */

.loading {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.loading:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 4px;
  /* animation: load 10s infinite; */
  animation: load 1.8s cubic-bezier(0, 0.98, 1, 0.99) infinite;
}

@keyframes load {
  0% {
    width: 100%;
    background: url("../assets/images/hamburguer.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  25% {
    width: 100%;
    background: url("../assets/images/pizza.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  50% {
    width: 100%;
    background: url("../assets/images/sushi.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  75% {
    width: 100%;
    background: url("../assets/images/couve-flor.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  100% {
    width: 100%;
    background: url("../assets/images/batatas-frita.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
@media only screen and (max-width: 768px) {
  .card-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    margin: 0 auto;
    max-width: 100%;
    padding: 1rem;
    width: 100%;
  }

  .card {
    height: 12rem;
    width: 100%;
  }

  .ball-movement,
  .square-card{
    display: none !important;
  }

  @keyframes down {
    0% {
      transform: translateY(-4rem);
    }
    20%,
    100% {
      transform: translateY(0);
    }
  }

  @keyframes up {
    0%,
    75% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-4rem);
    }
  }

  @keyframes squeeze {
    0%,
    4% {
      transform: scale(1);
    }
    45% {
      transform: scale(1.2, 0.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes ballBouncing {
    0%,
    100% {
      transform: scale(1.5, 0.5);
    }
    20% {
      transform: scaleY(1.2);
    }
    40%,
    80% {
      transform: translateY(-3rem);
    }
    70% {
      transform: translateY(-6rem);
    }
    90% {
      transform: translateY(0);
    }
  }
}
</style>


