<template>
  <div>
    <div class="h-auto flex items-center justify-center py-5 mb-10">
      <router-link :to="{ name: 'home' }">
        <h1
          class="text-gray-800 font-extrabold text-2xl"
          style="font-family: 'Gluten', cursive"
        >
          putzfome
        </h1>
      </router-link>
    </div>

    <div class="base__container_checkout px-4">
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-x-44 gap-y-4 px-2 relative"
      >
        <div class="space-y-5">
          <h1 class="text-2xl md:text-4xl font-semibold">
            Finalize seu pedido
          </h1>
          <!---Opção de entrega ou retirada--->
          <div>
            <div class="flex space-x-5">
              <button
                class="
                  focus:text-red-700 focus:outline-none
                  font-semibold
                  text-gray-500
                "
                :class="{ 'text-red-700': deliver }"
                @click.prevent="deliverOrder"
              >
                Entrega
              </button>

              <button
                v-if="lojaInCart.customEnableWithdraw"
                class="
                  focus:text-red-700 focus:outline-none
                  font-semibold
                  text-gray-500
                "
                :class="{ 'text-red-700': withdraw }"
                @click.prevent="withdrawOrder"
              >
                Retirar
              </button>
            </div>

            <div v-if="deliver" class="py-5">
              <button
                v-if="selectedAddress.id == null"
                @click.prevent="openModalAddressClient"
                class="text-red-700 rounded border border-red-300 py-1 px-3"
              >
                <span> Informe um edereço</span>
              </button>

              <!--printa dados de entrega-->

              <div
                v-if="selectedAddress.id"
                class="flex space-x-2 mt-10 justify-between"
              >
                <div class="flex space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <div>
                    <p class="text-sm text-gray-500">
                      {{ selectedAddress.street }},
                      {{ selectedAddress.number }}
                    </p>
                    <p class="text-sm text-gray-500">
                      {{ selectedAddress.complement }}
                    </p>
                    <p class="text-sm text-gray-500">
                      {{ selectedAddress.neighborhood }} - Quatá/SP
                    </p>

                    <span class="text-sm text-gray-500">{{
                      selectedAddress.reference_point
                    }}</span>
                  </div>
                </div>
                <div>
                  <button
                    @click.prevent="openModalAddressClient"
                    class="text-red-700 rounded border border-red-500 py-1 px-3"
                  >
                    <span v-if="selectedAddress.id == null">
                      Informe um edereço</span
                    >
                    <p v-else-if="deliver">Trocar</p>
                  </button>
                </div>
              </div>
            </div>
            <!--print dados de retirada-->
            <div v-else class="flex space-x-2 mt-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <div>
                <p class="text-sm text-gray-500">
                  {{ lojaInCart.street }},
                  {{ lojaInCart.number }}
                </p>
                <p class="text-sm text-gray-500">
                  {{ lojaInCart.neighborhood }} - Quatá/SP
                </p>
                <span class="text-sm text-gray-500">{{
                  lojaInCart.complement
                }}</span>
                <span class="text-sm text-gray-500">{{
                  lojaInCart.reference_point
                }}</span>
              </div>
            </div>
          </div>
          <!---Tempo de entrega--->
          <div class="flex space-x-4" v-if="deliver">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div>
              <p class="text-gray-500 font-semibold">Entrega</p>
              <span class="text-gray-500 text-sm"
                >Hoje, {{ lojaInCart.deliveryTime }} min</span
              >
            </div>
          </div>

          <!---Tempo de retirada--->
          <div class="flex space-x-4" v-if="withdraw">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div>
              <p class="text-gray-900">Retirar após</p>
              <span class="text-gray-500 text-sm"
                >Hoje, {{ lojaInCart.timeToWithdraw }} min</span
              >
            </div>
          </div>

          <!---Opção Pagamento--->

          <div class="space-y-5">
            <div class="flex space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                />
              </svg>
              <button class="text-gray-500 font-semibold">
                Como você vai pagar?
              </button>
            </div>

                      

            <div class="flex items-center space-x-5">
              <div class="flex items-center space-x-1">
                <input
                  id="cash"
                  type="radio"
                  :value="0"
                  v-model="newOrder.payment"
                />
                <label for="cash" class="cursor-pointer text-gray-500"
                  >Dinheiro</label
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>

              <div
                class="flex items-center space-x-1"
                v-if="lojaInCart.customEnablePix"
              >
                <input
                  id="pix"
                  type="radio"
                  :value="2"
                  v-model="newOrder.payment"
                />
                <label for="pix" class="cursor-pointer text-gray-500"
                  >Pix</label
                >
                <img src="@/assets/images/pix.png" alt="" class="w-4 " />
              </div>

              <div
                class="flex items-center space-x-1"
                v-if="lojaInCart.customEnableCardCredit"
              >
                <input
                  id="card"
                  type="radio"
                  :value="1"
                  v-model="newOrder.payment"
                />
                <label for="card" class="cursor-pointer text-gray-500"
                  >Cartão</label
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                  <path
                    fill-rule="evenodd"
                    d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>

             <p class="mb-3 text-gray-500 text-sm">* Pagamento é diretamente com o lojista na entrega.</p>
            

            <div v-if="pickedPayment == 0">
              <div class="flex items-center space-x-2 my-5">
                <input
                  type="checkbox"
                  v-model="needChangeMoney"
                  id="needChangeMoney"
                />
                <label for="needChangeMoney" class="text-sm cursor-pointer"
                  >Precisa de troco?</label
                >
              </div>

              <div v-if="needChangeMoney">
                <p>Troco pra quanto?</p>
                <money
                  v-model="newOrder.change_for"
                  v-bind="money"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                ></money>
              </div>
            </div>

            <div
              v-if="pickedPayment == 1"
              class="grid grid-cols-2 grid-rows-2 gap-2 my-5"
            >
              <div
                class="
                  border border-gray-200
                  md:p-3
                  p-2
                  rounded
                  relative
                  flex flex-col
                  items-center
                  justify-between
                "
                v-for="(item, index) in lojaInCart.paymentMethods"
                :key="index"
                v-show="item.identify !== 'pix' "
              >
                <div class="flex space-x-2 items-center"  >      

                  <img 
                    :src="item.image"
                    alt=""
                    class="w-4"
                  /> 
                  <input
                    :id="item.identify"
                    type="radio"
                    :value="item.id"
                    v-model="newOrder.picked_card"
                  />
                  <label
                    :for="item.identify"
                    class="
                      absolute
                      top-0
                      left-0
                      w-full
                      h-full
                      flex
                      items-center
                      justify-center
                      cursor-pointer
                    "
                  ></label>
                </div>
                <span
                  class="line-clamp-1 md:text-sm text-xs mt-1 text-gray-800"
                  >{{ item.description }}</span
                >
              </div>
            </div>

            <div v-if="pickedPayment == 2">
              
              <div
                class="
                  border border-gray-200
                  md:p-3
                  p-2
                  rounded
                  relative
                  flex flex-col
                  items-center
                  justify-between
                "
                v-for="(item, index) in lojaInCart.paymentMethods"
                :key="index"
                v-show="item.type === 'pix'"
              >
              
                <div
                  class="flex space-x-2 items-center"
                  v-if="item.type === 'pix'"
                >
                  <img
                    src="@/assets/images/pix.png"
                    alt=""
                    class="w-3 mx-3"
                  />
                  <!-- <input
                    :id="item.identify"
                    type="radio"
                    :value="item.identify"
                    v-model="newOrder.picked_card"
                  /> -->
                  <!-- <label
                    :for="item.identify"
                    class="
                      absolute
                      top-0
                      left-0
                      w-full
                      h-full
                      flex
                      items-center
                      justify-center
                      cursor-pointer
                    "
                  ></label> -->
        
                  {{item.pivot.numberPix }}

                </div>
              </div>
            </div>


          </div>

          <!---CPF  na nota--->
          <div class="my-14">
            <the-mask
              v-model="newOrder.cpf"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              placeholder="CPF/CNPJ na nota"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>

          <!---Botão finalizar pedido Desk--->
          <div
            class="
              bg-green-400
              rounded
              text-white text-center
              relative
              hidden
              md:block
              bottom-3
              w-full
              left-0
            "
          >
            <button class="py-4 px-8 w-full" v-if="loading">
              <div class="flex items-center justify-center">
                <span> Falta pouco. Aguarde</span>
                <img src="@/assets/images/preloader.png" class="w-5 h-5 ml-2" />
              </div>
            </button>
            <button
              class="py-4 px-8 w-full"
              @click.prevent="createNewOrder"
              v-else
            >
              Fazer pedido
            </button>
          </div>
        </div>

        <div class="bg-white pb-20">
          <div
            v-if="products.length > 0"
            class="w-full relative overflow-hidden px-5"
            style="
              box-shadow: 0px 4px 8px rgb(0 0 0 / 10%);
              border: 1px solid #f7f7f7;
            "
          >
            <div class="flex flex-col justify-between">
              <div class="flex-grow: 0 space-y-2 border-b border-gray-200 py-4">
                <div class="space-y-1">
                  <p class="text-gray-500 font-light text-sm">Seu pedido em</p>
                  <p
                    class="
                      text-xl
                      line-clamp-2
                      w-full
                      text-gray-500
                      font-semibold
                    "
                  >
                    {{ lojaInCart.name }}
                  </p>
                </div>
              </div>

              <div
                class="flex-grow: 1 py-4"
                style="max-height: 60%; overflow: auto"
              >
                <div
                  v-for="(item, index) in products"
                  :key="index"
                  class="border-b border-gray-200"
                >
                  <div class="flex justify-between py-2">
                    <div class="line-clamp-2 text-sm text-gray-700">
                      {{ item.qty }} X {{ item.product.name }}
                    </div>
                    <div class="w-1/2 text-right mr-4 font-semibold">
                      R$ {{ item.totalPrice | formatprice }}
                    </div>
                  </div>
                  <!--complementos -->
                  <div
                    v-for="(complement, index_) in item.complements"
                    :key="index_"
                  >
                    <p class="text-gray-900 text-sm">{{ complement.name }}</p>

                    <span
                      class="text-sm"
                      v-for="(option, indice) in complement.optionals"
                      :key="indice"
                    >
                      <span class="text-gray-600 text-xs">
                        {{ option.qtd }} x {{ option.name }}
                        <span class="hidden">
                          R$ {{ option.price | formatprice }}</span
                        >,
                      </span>
                    </span>
                  </div>
                  <!--complementos -->
                  <div class="py-2" v-if="item.obs">
                    <p class="text-gray-500 font-extralight text-sm">
                      Obs: <span>{{ item.obs }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-grow: 0 space-y-2 my-5 py-4 text-sm">
                <div class="flex justify-between">
                  <span class="text-gray-500 font-semibold">Subtotal</span
                  ><span class="font-semibold text-gray-700"
                    >R$ {{ subTotalCart | formatprice }}</span
                  >
                </div>
                <div class="flex justify-between pb-2">
                  <span class="text-gray-500 font-semibold"
                    >Taxa de entrega ?</span
                  >
                  <span class="text-green-500" v-if="taxaFrete === 0"
                    >Grátis</span
                  >
                  <span v-else>{{ taxaFrete | formatprice }}</span>
                </div>
                <div class="flex justify-between border-t border-gray-200 py-2">
                  <span class="text-gray-500 font-semibold">Total </span>
                  <span class="text-gray-700 font-semibold">
                    R$ {{ totalCart | formatprice }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!---Botão finalizar pedido Mobile--->
        <div
          class="
            bg-green-400
            rounded
            text-white text-center
            md:hidden
            absolute
            bottom-3
            w-full
            left-0
          "
        >
          <button class="py-4 px-8 w-full" v-if="loading">
            <div class="flex items-center justify-center">
              <span> Falta pouco. Aguarde</span>
              <img src="@/assets/images/preloader.png" class="w-5 h-5 ml-2" />
            </div>
          </button>
          <button
            class="py-4 px-8 w-full"
            @click.prevent="createNewOrder"
            v-else
          >
            Fazer pedido
          </button>
        </div>
      </div>
    </div>

    <!-- modal selecionar endereço de entrega-->
    <modal name="address_client" :minHeight="450" :adaptive="true">
      <div
        class="p-5 modal_scrollable"
        style="height: inherit; display: flex; flex-direction: column"
      >
        <div class="flex justify-end">
          <button @click.prevent="closeModalAddressClient">❌</button>
        </div>

        <div class="py-2">
          <h4 class="text-gray-700">Selecione um endereço</h4>
        </div>

        <div
          class="py-8 space-y-4"
          style="flex-grow: 1"
          v-if="me.addresses.length > 0"
        >
          <div
            v-for="(address, index) in me.addresses"
            :key="index"
            class="
              b-green-700
              border
              rounded
              py-2
              px-4
              cursor-pointer
              flex
              justify-between
              box_address
            "
            :class="{
              'border-green-400 border': selectedAddress.id === address.id,
            }"
          >
            <div @click.prevent="setAddress(address)" class="flex-1">
              <div class="flex justify-between items-center">
                <p class="font-light text-sm text-gray-600">
                  {{ address.street }}, {{ address.number }}
                </p>
              </div>

              <p class="font-bold text-sm text-gray-600">
                {{ address.complement }}
              </p>
            </div>

            <div class="bt_delete_address space-x-2">
              <button @click.prevent="openModalDeliveryAddressEdit(address)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              </button>
              <button @click.prevent="deleteAddressClient(address, index)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          v-else
          class="py-8 space-y-4 flex justify-center items-center"
          style="flex-grow: 1"
        >
          <div class="flex justify-center flex-col items-center space-y-1">
            <h1 class="font-extrabold text-2xl text-gray-800">OPS,</h1>
            <p class="text-gray-800 text-center">
              Você ainda não tem nenhum endereço cadastrado.
            </p>
            <p class="text-gray-800">Clique abaixo em inserir novo</p>
          </div>
        </div>

        <footer class="flex justify-center">
          <button
            class="bg-green-400 w-full md:w-2/6 py-3 px-3 rounded text-white"
            button
            @click.prevent="openModalDeliveryAddress"
          >
            <span class="text-white">Inserir novo</span>
          </button>
        </footer>
      </div>

      <!-- modal novo endereço de entrega-->
      <modal name="delivery-address" :height="'auto'" :adaptive="true">
        <div
          class="p-5"
          style="height: inherit; display: flex; flex-direction: column"
        >
          <div class="flex justify-end">
            <button @click.prevent="closeModalDeliveryAddress">❌</button>
          </div>

          <div class="py-2">
            <h4 class="text-gray-700">Cadastre seu endereço:</h4>
          </div>

          <div class="py-8 space-y-4">
            <div
              class="
               space-y-3
              "
            >
              <input
                name="neighborhood"
                type="neighborhood"
                v-model="formNewAaddress.neighborhood"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                 
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Bairro"
              />
              <div
                v-if="errors.neighborhood != ''"
                class="text-red-700 text-xs "
              >
                {{ errors.neighborhood[0] || "" }}
              </div>

              <input
                name="street"
                type="street"
                v-model="formNewAaddress.street"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Rua"
              />
              <div v-if="errors.street != ''" class="text-red-700 text-xs">
                {{ errors.street[0] || "" }}
              </div>
            </div>

            <div
              class="
                flex flex-col
                md:flex-row
                justify-between
                md:space-x-3
                space-y-2
                md:space-y-0
              "
            >
              <div>
                <input
                  name="number"
                  type="number"
                  v-model="formNewAaddress.number"
                  class="
                  appearance-none
                  rounded
                  relative
                  block
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                  placeholder="Número"
                />
                <div
                  v-if="errors.number != ''"
                  class="text-red-700 text-xs mt-1"
                >
                  {{ errors.number[0] || "" }}
                </div>
              </div>

              <input
                name="complement"
                type="complement"
                v-model="formNewAaddress.complement"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Complemento"
              />
            </div>

            <div>
              <input
                name="reference_point"
                type="reference_point"
                v-model="formNewAaddress.reference_point"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Ponto de referência"
              />
            </div>

            <footer class="flex justify-center">
              <button
                class="bg-green-400 w-4/6 py-4 px-5 rounded text-white"
                button
                @click.prevent="createNewAddresses"
              >
                <span>Salvar endereço</span>
              </button>
            </footer>
          </div>
        </div>
      </modal>
      <!-- fim modal-->

      <!-- modal editar endereço de entrega-->
      <modal name="delivery-address-edit" :height="'auto'" :adaptive="true">
        <div
          class="p-5"
          style="height: inherit; display: flex; flex-direction: column"
        >
          <div class="flex justify-end">
            <button @click.prevent="closeModalDeliveryAddressEdit">❌</button>
          </div>

          <div class="py-2">
            <h4 class="text-gray-700">Ediatr endereço:</h4>
          </div>

          <div class="py-8 space-y-4">
            <div class="space-y-3">
              <input
                name="neighborhood"
                type="neighborhood"
                v-model="formEditAaddress.neighborhood"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Bairro"
              />
              <div
                v-if="errors.neighborhood != ''"
                class="text-red-700 text-xs"
              >
                {{ errors.neighborhood[0] || "" }}
              </div>

              <input
                name="street"
                type="street"
                v-model="formEditAaddress.street"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Rua"
              />
              <div v-if="errors.street != ''" class="text-red-700 text-xs">
                {{ errors.street[0] || "" }}
              </div>
            </div>

            <div
              class="
                flex flex-col
                md:flex-row
                justify-between
                md:space-x-3
                space-y-2
                md:space-y-0
              "
            >
              <div>
                <input
                  name="number"
                  type="number"
                  v-model="formEditAaddress.number"
                  class="
                  appearance-none
                  rounded
                  relative
                  block
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                  placeholder="Número"
                />
                <div
                  v-if="errors.number != ''"
                  class="text-red-700 text-xs mt-1"
                >
                  {{ errors.number[0] || "" }}
                </div>
              </div>

              <input
                name="complement"
                type="complement"
                v-model="formEditAaddress.complement"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Complemento"
              />
            </div>

            <div>
              <input
                name="reference_point"
                type="reference_point"
                v-model="formEditAaddress.reference_point"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Ponto de referência"
              />
            </div>

            <footer class="flex justify-center">
              <button
                class="bg-green-400 w-4/6 py-4 px-5 rounded text-white"
                button
                @click.prevent="editAddressClient"
              >
                <span>Salvar alteração</span>
              </button>
            </footer>
          </div>
        </div>
      </modal>
      <!-- fim modal-->

      <ModalAlertGeral
        :isVisible="modalAlertGeral.open"
        :message="modalAlertGeral.message"
        :title="modalAlertGeral.title"
        :erro="modalAlertGeral.erro"
        @close-modal-alert="modalAlertGeral.open = false"
      />
    </modal>

    <ModaLojaClose
      :isVisible="modaLojaClose"
      :tenant="lojaInCart"
      @close-modal-loja="modaLojaClose = false"
    />
    <ModalAlertGeral
      :isVisible="modalAlertGeral.open"
      :message="modalAlertGeral.message"
      :title="modalAlertGeral.title"
      :erro="modalAlertGeral.erro"
      @close-modal-alert="modalAlertGeral.open = false"
    />

    <div v-if="loading">
      <LoadingOrder />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ModaLojaClose from "../../components/ModalLojaClose.vue";
import ModalAlertGeral from "../../components/ModalAlertGeral.vue";
import LoadingOrder from "../../components/LoadingOrder";
export default {
  components: {
    ModaLojaClose,
    ModalAlertGeral,
    LoadingOrder,
  },
  data() {
    return {
      loading: false,
      modaLojaClose: false,

      modalAlertGeral: {
        open: false,
        message: "",
        title: "",
        erro: false,
      },

      deliver: true, // entregar
      withdraw: false, //retirar
      customMminimumOrder: false,
      needChangeMoney: false, //Precisa de troco

      newOrder: {
        // picked: null, // qual forma de pagamento escolheu..? R$ ou cartão
        picked_card: null, //cartão escolhido
        delivery: 1, //Entrega? 1 == sim // 0 == retirar no local
        payment: null, //tipo de pagamento? 1 == cartão // 0 = dinheiro R$ // 2 == pix
        change_for: "", // Troco para quantos? somente quando payment == 0
        cpf: null,
      },

      formNewAaddress: {
        // form criar novo endereço entrega
        id: null,
        street: "", //rua
        neighborhood: "", // bairro
        number: "",
        complement: "",
        reference_point: "", // ponto de referencia
        default_address: null,
      },

      formEditAaddress: {
        // form editar endereço entrega
        id: null,
        street: "", //rua
        neighborhood: "", // bairro
        number: "",
        complement: "",
        reference_point: "", // ponto de referencia
        default_address: null,
      },

      //Endereço selecionado para entrega
      selectedAddress: {
        id: null,
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
        reference_point: "",
      },

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      errors: {
        street: "", //rua
        neighborhood: "", // bairro
        number: "",
        complement: "",
        reference_point: "",
      },
    };
  },
  created() {
    if (!localStorage.getItem("token_sanctum")) {
      this.$router.push({ name: "login" });
    }
    if (this.products.length === 0) {
      this.$router.push({ name: "home" });
    }
    if (this.me.defaultAddress) {
      this.selectedAddress = this.me.defaultAddress;
    }

    this.searchCurrentStoreInCart(this.lojaInCart.uuid);
  },
  methods: {
    ...mapActions([
      "createOrder",
      "createAddresses",
      "editAddresses",
      "deleteAddresses",
      "SetdAddressSelected",
      "searchCurrentStoreInCart",
    ]),

    openModalDeliveryAddress() {
      this.$modal.show("delivery-address");
    },
    closeModalDeliveryAddress() {
      this.$modal.hide("delivery-address");
    },
    openModalDeliveryAddressEdit(address) {
      this.$modal.show("delivery-address-edit");

      // form editar endereço entrega
      (this.formEditAaddress.id = address.id),
        (this.formEditAaddress.street = address.street);
      this.formEditAaddress.neighborhood = address.neighborhood;
      this.formEditAaddress.number = address.number;
      this.formEditAaddress.complement = address.complement;
      this.formEditAaddress.reference_point = address.reference_point;
      this.formEditAaddress.default_address = address.default_address;
    },
    closeModalDeliveryAddressEdit() {
      this.$modal.hide("delivery-address-edit");
      this.resetformEditAaddress();
    },
    openModalAddressClient() {
      this.$modal.show("address_client");
    },
    closeModalAddressClient() {
      this.$modal.hide("address_client");
    },
    setAddress(address) {
      this.SetdAddressSelected(address.id);
      this.selectedAddress = address;
      this.closeModalAddressClient();
    },
    createNewAddresses() {
      this.reseteErros();
      this.createAddresses(this.formNewAaddress)
        .then(() => {
          this.resetformNewAaddress();
          this.closeModalDeliveryAddress();
        })
        .catch((error) => {
          this.errors = Object.assign(this.errors, error.response.data.errors);
        });
    },
    editAddressClient() {
      this.reseteErros();
      this.editAddresses(this.formEditAaddress)
        .then(() => {
          this.closeModalDeliveryAddressEdit();
          if (this.me.defaultAddress) {
            this.selectedAddress = this.me.defaultAddress;
          }
        })
        .catch((error) => {
          this.errors = Object.assign(this.errors, error.response.data.errors);
        });
    },
    deleteAddressClient(address) {
      if (this.me.defaultAddress.id === address.id) {
        this.modalAlertGeral.open = true;
        this.modalAlertGeral.message = "Você não pode remover o endereço atual";
        return false;
      }
      this.deleteAddresses(address.id).then((response) => {
        if (this.selectedAddress.id === response.data.id) {
          this.selectedAddress = this.me.defaultAddress;
        }
      });
    },

    deliverOrder() {
      this.deliver = true; // entregar pedido
      this.withdraw = false; // retirar pedido
      this.newOrder.delivery = 1;
    },
    withdrawOrder() {
      this.withdraw = true;
      this.deliver = false;
      this.newOrder.delivery = 0;
    },
    createNewOrder() {
      //verefica se loja esta aberta para receber pedido
      if (!this.lojaInCart.tenantOpen) {
        this.modaLojaClose = true;
        return false;
      }

      if (!this.ChecksIfAllDataHaveBeenFilled()) {
        return false;
      }

      this.loading = true;

      let params = {
        token_company: this.lojaInCart.uuid, // this.company.uuid,
        products: this.products,
        change_for: this.newOrder.change_for,
        address_id: this.selectedAddress.id,
        ...this.newOrder,
      };

      this.createOrder(params)
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "success",
            title: "Pedido realizado com sucesso",
          });

          setTimeout(() => {
            this.$router.push({
              name: "detail.order",
              params: { identify: response.identify },
            });
            this.loading = false;
          }, 4000);
        })
        .catch((error) => {
          // setTimeout(() => {
            if (error.response.data.error === "ProductNotException") {
              this.modalAlertGeral.open = true;
              this.modalAlertGeral.message =  `
              ${error.response.data.message}. 
              Você será redirencionado para a home.
              `
              this.modalAlertGeral.erro = true;
            }
            this.loading = false;
          // }, 1000);

          // this.$swal.fire({
          //   toast: true,
          //   position: "top-end",
          //   showConfirmButton: false,
          //   timer: 3000,
          //   timerProgressBar: true,
          //   didOpen: (toast) => {
          //     toast.addEventListener("mouseenter", this.$swal.stopTimer);
          //     toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          //   },
          //   icon: "error",
          //   title: "Erro ao fazer pedido",
          // });
        });
    },
    //verifica Se Todos Dados Foram Preenchidos
    ChecksIfAllDataHaveBeenFilled() {
      if (!this.selectedAddress.id && this.deliver) {
        this.modalAlertGeral.open = true;
        this.modalAlertGeral.message =
          "Escolhe um endereço onde deseja receber o pedido";
        return false;
      }

      if (this.newOrder.payment === null) {
        this.modalAlertGeral.open = true;
        this.modalAlertGeral.message = "Escolhe uma forma de pagamento";
        return false;
      }
      if (this.newOrder.payment == 1 && !this.newOrder.picked_card) {
        this.modalAlertGeral.open = true;
        this.modalAlertGeral.message = "Escolhe um cartão para continuar";
        return false;
      }
      if (this.newOrder.payment === 0) {
        if (this.needChangeMoney) {
          if (!this.checkIfChangeIsLessTanTotal()) {
            return false;
          }
        }
      }

      return true;
    },
    //verificaSeTrocoéMenosQueTotalPedido
    checkIfChangeIsLessTanTotal() {
      if (
        parseInt(this.newOrder.change_for.toFixed(2).replace(".", "")) <
        this.totalCart
      ) {
        this.modalAlertGeral.open = true;
        this.modalAlertGeral.message =
          "Valor para troco deve ser maior que total do pedido.";
        return false;
      }
      return true;
    },
    resetformNewAaddress() {
      this.formNewAaddress = {
        id: null,
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
        reference_point: "",
        default_address: null,
      };
    },
    resetformEditAaddress() {
      this.formEditAaddress = {
        id: null,
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
        reference_point: "",
        default_address: null,
      };
    },
    reseteErros() {
      this.errors = {
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
        reference_point: "",
      };
    },
  },
  computed: {
    pickedPayment() {
      return this.newOrder.payment;
    },
    ...mapGetters({
      subTotalCart: "subTotalCart",
      totalCart: "totalCart",
      taxaFrete: "taxaFrete",
    }),
    ...mapState({
      products: (state) => state.cart.products,
      lojaInCart: (state) => state.cart.loja,
      me: (state) => state.auth.me,
      company: (state) => state.companies.companySelected,
    }),
  },
};
</script>

<style scoped>
[type="checkbox"]:focus,
[type="radio"]:focus {
  box-shadow: none;
}
.modal_scrollable {
  overflow: auto;
}
.vm--overlay .vm--modal {
  background-color: red !important;
  width: 90% !important;
}
.bt_delete_address {
  opacity: 0;
}
.box_address:hover > .bt_delete_address {
  opacity: 1;
}
</style>
